.loginWrapper {
  font-family: 'Lato', sans-serif;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.errorMessage {
  color: red;
  padding-bottom: 10px;
}
