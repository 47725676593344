:root {
  --cream-can: #f2c75c;
  --brandy-punch: #dc8633;
  --half-baked: #86c8bc;
  --orange-roughy: #be531c;
  --mule-fawn: #8d3f2b;
  --cornflower: #a7c6ed;
  --nugget: #c1a01f;
  --shadow: #89764b;
  --vista-blue: #8fd6bd;

  --lincoln-green: #3f6634;
  --artichoke: #9ea290;
  --indigo-dye: #133c55;
  --mellon: #ffc2b4;
}

html,
body,
#reactapp,
.App {
  margin: 0;
}

h4 {
  margin: 0;
}

.backgroundImage {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 5% 10%;
  font-family: 'Lato', sans-serif;
}

header {
  height: 10%;
  align-items: center;
  padding: 16px;
}

nav li {
  background-color: var(--indigo-dye);
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  color: var(--mellon);
  padding-top: 4px;
}

.body {
  display: flex;
  flex-direction: row;
}
article {
  padding: 4%;
  background-color: var(--mellon);
  display: flex;
  flex: 2;
}

.extraInformation {
  margin-top: 16px;
  background-color: var(--indigo-dye);
  border-radius: 20px;
  padding: 16px;
  float: right;
  text-align: center;
}

aside {
  margin: 10% 4%;
  padding: 4%;
  border: 1px;
  background-color: var(--artichoke);
  box-shadow: 5px 5px #888888;
}

footer {
  padding: 2%;
  background-color: var(--indigo-dye);
  color: var(--mellon);
  text-align: center;
}

.asideWrapper {
  background-image: url(../../media/bg_planting.jpg);
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.asideHeading {
  background-color: var(--artichoke);
}

.pageReference {
  border-radius: 20px;
  background-color: var(--indigo-dye);
  box-shadow: none;
  color: white;
  display: inline-block;
  margin-bottom: 12px;;
}

audio {
  filter: grayscale(1) contrast(99%) invert(12%);
}
